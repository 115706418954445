import React from 'react'
import antrag from '../files/FoerderantragMusikzugLauenburg-Sued.pdf'

function Foerdern() {
  return (
    <main className="px-5  sm:px-16 sm:pt-10 pt-5 font-light">
      <h1 className='text-2xl font-semibold mb-5 w-auto m-auto text-center'>Förderverein</h1>

      <p className='text-justify'>
        Der Förderverein “FÖVI e.V” wurde 2008 gegründet und sorgt seitdem für die Finanzierung der Musikinstrumente,
        Musikausbildung, Musikworkshops und vieles mehr ausschließlich für den Jugendfeuerwehrmusikzug.
        Von den Einnahmen des Fördervereins werden 100% dem Spendenzweck zugeführt, die Verwaltungskosten
        wie z.B. Bankgebühren sind allein durch die fixen Mitgliedsbeiträge abgedeckt - wir finden dies sehr gut so!
      </p>
      <hr className=' my-6 border-t-2 border-zinc-300' />
      <h1 className='text-2xl font-semibold mb-5 w-auto m-auto text-center'>Fördern Sie auch den Jugendmusikzug!</h1>

      <p className='text-justify mb-2'>
        Auch Sie können durch eine einmalige oder wiederkehrende Spende die musikalische Arbeit und die Jugendarbeit 
        im Jugendfeuerwehrmusikzug unterstützen! Laden Sie 
        <a href={antrag} download="FoerderAntrag.pdf"> <b> <i  className='hover:underline text-red-800'>hier »</i></b> </a>
         
         das Formular für einen Förderantrag herunter oder überweisen Sie 
        direkt einen Beitrag:
      </p>
      <p className='text-justify mb-2 pl-4'>
        <i>
          Förderverein für den Jugendfeuerwehrmusikzug Lauenburg-Süd e.V.</i> <br />
        <b>1. Vorsitzender:</b> Stefan Ehmer <br />
        <b>2. Vorsitzender:</b> Detlef Koop <br />
        <b>Sitz:</b> Dorfstraße 32, 21483 Lanze
      </p>
      <p className='text-justify mb-2'>
        <b>Vereinsregisterkennung</b> VR 3041 HL - Amtsgericht Lübeck
      </p>
    </main>
  )
}

export default Foerdern