import React, { Component } from "react";
import axios from 'axios';

export default class Kontakt extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: "",
        email: "",
        subject: "",
        message: "",
        sent: false,
        error: null
      },
      showAlert: false,
      alertMessage: ""
    };
  }

  RenderMessage(event) {
    event.preventDefault();
    const tmp = "<html> <body> <h4>Kontaktformular Musikzug </h4> <br> Name: " + this.state.data.name + "<br> <br>Email: " + this.state.data.email
      + "<br> <br> Nachricht: " + this.state.data.message + "</body> </html>";
    this.setState({ data: { ...this.state.data, message: tmp } }, () => { this.FormSubmittionHandler(); });
  }
 
  FormSubmittionHandler() {
    if (/^$/.test(this.state.data.subject) || /(^$)/.test(this.state.data.message)
      || /(^$)/.test(this.state.data.name) || /(^$)/.test(this.state.data.email)) {
      this.setState({ alertMessage: "Bitte fülle alle Felder aus!" });
      this.setState({ showAlert: true });
    } else if (!/(.+)[ ](.+)/.test(this.state.data.name)) {
      this.setState({ alertMessage: "Bitte geb den Vor- und Nachnamen an!" });
      this.setState({ showAlert: true });
    } else if (!/(.+)@(.+){2,}\.(.+){2,}/.test(this.state.data.email)) {
      this.setState({ alertMessage: "Bitte geb eine gültige E-Mail Adresse an!" });
      this.setState({ showAlert: true });
    } else if (!document.getElementById('check').checked) {
      this.setState({ alertMessage: "Bitte stimme der Datenschutzerklärung zu!" });
      this.setState({ showAlert: true });
    } else if (document.getElementById('one').value.length !== 0) {
      return;
    } else {
      this.resetForm();
      axios({
        method: 'post',
        url: window.location.protocol + "//" + window.location.hostname
          + ":" + window.location.port + "/api/index.php",
        headers: { 'content-type': 'application/json' },
        data: this.state.data
      }).then(result => {
        this.setState({ mailSent: result.data.sent });
        this.setState({ alertMessage: "Nachricht wurde versendet!" });
        this.setState({ showAlert: true });
      }).catch(error => {
        this.setState({ error: error.message });
        this.setState({ alertMessage: "Sendung ist Fehlgeschlagen!" });
        this.setState({ showAlert: true });
      });
    }
  }

  resetForm() {
    document.getElementById('name').value = "";
    document.getElementById('mail').value = "";
    document.getElementById('betreff').value = "";
    document.getElementById('text').value = "";
    document.getElementById("check").checked = false;
  }

  render() {
    return (
      <main className="px-5  sm:px-16 sm:pt-10 pt-5 font-light">
        <h1 className='text-2xl font-semibold mb-5 w-auto m-auto text-center'>Kontaktformular</h1>
        <div className='flex lg:flex-row flex-col'>
          <div className='lg:w-1/4 text-justify lg:border-b-0 border-zinc-300 border-b-2 pb-4 mb-4'>
            <p>
              Bei Wünschen, Fragen oder Anregungen kannst du uns gerne anrufen oder uns über das Kontaktformular eine Nachricht schreiben. Wir antworten dir schnellst möglich.
            </p>
            <h3 className=' font-semibold mt-5 mb-2'>Vorsitzender</h3>
            <p>Ulrich Grimm</p>
            <p>Dorfstraße 32</p>
            <p>21483 Lanze</p>
            <p>Tel.: 04153/54619</p>
          </div>
          <form className='flex flex-col lg:w-3/4 lg:border-l-2 border-zinc-300 lg:pl-4 lg:ml-4' action='#'>
            <div className='grid grid-rows-3 grid-col-1 gap-4 '>
              <input id='name' onChange={(e) => this.setState({ data: { ...this.state.data, name: e.target.value } })} className=' h-10 row-start-1 border-b border-zinc-500 appearance-none bg-transparent py-1 px-2 leading-tight focus:outline-none' type="" placeholder='Name' />
              <input id='mail' onChange={(e) => this.setState({ data: { ...this.state.data, email: e.target.value } })} className='row-start-2 border-b border-zinc-500 appearance-none bg-transparent py-1 px-2 leading-tight focus:outline-none ' type="" placeholder='E-Mail Adresse' />
              <input id='betreff' onChange={(e) => this.setState({ data: { ...this.state.data, subject: e.target.value } })} className='row-start-3 border-b border-zinc-500 appearance-none bg-transparent py-1 px-2 leading-tight focus:outline-none ' type="" placeholder='Betreff' />
              <input id='one' type="text" className='hidden' />
            </div>
            <textarea id='text' onChange={(e) => this.setState({ data: { ...this.state.data, message: e.target.value } })} rows={4} className='mt-4 mb-2 w-full border-b border-zinc-500 py-1 px-2 focus:outline-none transition-height duration-75 ease-in-out ' placeholder='Nachricht'></textarea>
            <div className=" flex flex-col sm:flex-row sm:justify-between sm:items-center mt-4">
              <div className="mr-2 sm:mb-0 mb-4">
                <input className=" appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-800 checked:border-red-800 hover:border-red-800 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" id="check" />
                <label>Ich stimme der <a href="/dsgvo"> <b> <i className='hover:underline text-red-800'>Datenschutzerklärung</i></b></a> zu</label>
              </div>
              <input type="submit" value="Nachricht senden" onClick={e => this.RenderMessage(e)} className='bg-red-800 mt-4 self-end hover:bg-red-900 max-w-xs text-white font-bold py-2 px-4 rounded items-center'></input>
            </div>
            {this.state.showAlert &&
              <div className='border-red-800 border-l-2 bg-zinc-100 flex items-center justify-center mt-2 px-2 py-2 w-full'>
                <p> {this.state.alertMessage} </p>
              </div>}
          </form>
        </div>
      </main>
    );
  }
}