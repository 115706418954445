import React, { useState } from 'react'
import logo from '../img/logo_small.png'
import up from '../img/winkel-klein-oben.png'
import down from '../img/winkel-klein-unten.png'

export default function Header() {

  const [dropdown, setDropdown] = useState(false);

  return (
    <div className='sticky top-0 w-full shadow-md backdrop-blur-sm flex-none text-black bg-white/80 z-40 border-red-800 border-t-2'>
      <nav className='flex flex-row justify-between items-center'>
        <a href="/startseite">
          <div className='flex flex-row items-center'>
            <img src={logo} alt="" className='h-20 m-2' />
            <h1 id="startseite" className='px-4 md:px-1 xl:px-4 target:text-grey-800 text-base sm:text-xl lg:text-xl md:text-sm font-bold sm:flex '>
            Feuerwehrmusikzug  Lauenburg-Süd
            </h1>
          </div>
        </a>
        <button type="button" className="pr-5 flex items-center md:hidden" onClick={() => setDropdown(!dropdown)}>
          <h1>Menü</h1>
          <img className="ml-3 w-10 h-10" src={dropdown ? up : down} alt="" />
        </button>
        <ul className='md:flex flex-row items-center p-4 text-md lg:text-xl md:text-md hidden '>
          <li id="startseite" className={`lg:p-4 p-2 ${(window.location.pathname === "/startseite" || window.location.pathname === "/") ? "border border-red-800" : ""}`}>
            <a href="/startseite">Startseite</a>
          </li>
          <li id="" className={`lg:p-4 p-2 ${(window.location.pathname === "/chronik") ? "border border-red-800" : ""}`}>
            <a href="/chronik">Chronik</a>
          </li>
          <li id="" className={`lg:p-4 p-2 ${(window.location.pathname === "/jugendmusikzug") ? "border border-red-800" : ""}`}>
            <a href="/jugendmusikzug">Jugendmusikzug</a>
          </li>
          <li id="" className={`lg:p-4 p-2 ${(window.location.pathname === "/termine") ? "border border-red-800" : ""}`}>
            <a href="/termine">Termine</a>
          </li>
          <li id="" className={`lg:p-4 p-2 ${(window.location.pathname === "/foerdern") ? "border border-red-800" : ""}`}>
            <a href="/foerdern">Fördern</a>
          </li>
          <li id="" className={`lg:p-4 p-2 ${(window.location.pathname === "/kontakt") ? "border border-red-800" : ""}`}>
            <a href="/kontakt">Kontakt</a>
          </li>
        </ul>
      </nav>
      <div style={{ transition: 'all 400ms ease-in-out', height: dropdown ? '317px' : '0' }} className=' overflow-y-hidden fixed flex flex-col items-center bg-red-800 opacity-90 w-full text-xl text-white md:hidden'>
          <a className='py-3 w-4/5 flex justify-center border-b border-zinc-300' href="/startseite">Startseite</a>
          <a className='py-3 w-4/5 flex justify-center border-b border-zinc-300' href="/chronik">Chronik</a>
          <a className='py-3 w-4/5 flex justify-center border-b border-zinc-300' href="/jugendmusikzug">Jugendmusikzug</a>
          <a className='py-3 w-4/5 flex justify-center border-b border-zinc-300' href="/termine">Termine</a>
          <a className='py-3 w-4/5 flex justify-center border-b border-zinc-300' href="/foerdern">Fördern</a>
          <a className='py-3 w-4/5 flex justify-center' href="/kontakt">Kontakt</a>
        </div>
    </div>
  )
}
