import React from 'react'
import plakat from '../img/Plakat-Konzert-2025.png'

function Termine() {
  return (
    <main className="px-5  sm:px-16 sm:pt-10 pt-5 font-light">
      <h1 className='text-2xl font-semibold mb-5 w-auto m-auto text-center'>Termine</h1>
      <div className='md:flex sm:justify-between'>
        <p className='md:w-3/5'>
          <h1 className='font-semibold text-xl'>Neujahrskonzert mit böhmischer Blasmusik</h1>
          <br />
          <b>Datum</b>: &ensp; Sonntag, 05.01.2025<br />
          <b>Uhrzeit</b>: &ensp; (Einlass) 15:00 Uhr, (Beginn) 16:00 Uhr<br />
          <b>Ort</b>: &emsp;&emsp; Gymnasium Schwarzenbek<br />
          &emsp;&emsp;&emsp;&emsp;  Buschkoppel 7, Schwarzenbek<br />
          <b>Preis</b>: &emsp;&ensp; 15 €<br />
          &emsp;&emsp;&emsp;&emsp; Kostenloser Eintritt für Kinder bis einschließlich 10 Jahre<br />
          <b>Kartenverkauf</b>:<br />
          &emsp;&emsp;&emsp;&emsp;• Tabakwaren-Fachgeschäft Franck (Reeperbahn 9, Lauenburg/E.) <br />
          &emsp;&emsp;&emsp;&emsp;• Café “Alte Marktschule” (Markt 4, Schwarzenbek)<br />
          &emsp;&emsp;&emsp;&emsp;• Über das <a href="/karten"> <b> <i className='hover:underline text-red-800'>Bestellformular</i></b> </a><br />
        </p>
        <img className='md:w-2/5' src={plakat} alt='Plakat Konzert 2025' />
      </div>
    </main>
  )
}

export default Termine