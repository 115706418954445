import React from "react";
import feuerwehr from "../img/feuerwehruniform_scale(2).png"
import black from "../img/feuerwehruniform_black.png"


const Startseite = () => {
    return (
        <div className="mx-auto">
            <h1 className="hidden"> Willkommen beim Musikzug Lauenburg-Süd</h1>
            <div className="grid grid-cols-1 ">
                <div className="relative col-start-1 row-start-1 flex flex-col-reverse 
                                bg-gradient-to-t from-black/100 via-black/30 items-center justify-center">
                    <h1 className="md:text-4xl text-2xl text-white font-semibold">  beim Musikzug Lauenburg-Süd
                    </h1>
                    <h1 className="md:text-4xl text-2xl text-white font-semibold">  Willkommen
                    </h1>
                </div>
                <div className="col-start-1 row-start-1 bg-black">
                    <img src={black} alt="" className="w-full  object-cover object-center" />
                </div>
                <div className="grid gap-4 col-start-1 row-start-1 ">
                    <img src={feuerwehr} alt=""
                        className="w-full  object-cover object-center" />
                </div>
            </div>
            <main className="px-5 sm:text-xl sm:px-16 sm:pt-10 pt-5 text-sm ">
                <div className="flex justify-center pb-2">
                    <p className="p-3 border-2 border-red-800 text-center">
                        <a href="\termine" className=" text-2xl mb-2 underline text-center hover:text-red-800">
                            NEUJARHRSKONZERT SAVE THE DATE!<br />
                        </a>
                        Sonntag, 05. Januar 2025 um 16.00 Uhr in Schwarzenbek
                    </p>
                </div>
                <article className="font-light flex flex-row flex-wrap justify-between 
                                    lg:grid 2 lg:grid-cols-2 lg:gap-10">
                    <div className="mb-5 col-start-1 row-start-1 lg:text-justify">
                        Der Musikzug Lauenburg-Süd begleitet viele Veranstaltungen musikalisch.
                        Einige Beispiele:
                        <ul className="list-disc list-inside mt-3">
                            <li>Feuerwehrfeste mit “großem Zapfenstreich”</li>
                            <li>Laternenumzüge</li>
                            <li>Stadt- und Dorffeste</li>
                            <li>Oktoberfeste</li>
                            <li>Weihnachtsmärkte</li>
                            <li>Jubiläums-Ständchen und vieles mehr</li>
                        </ul>
                    </div>
                    <p className="text-justify col-start-2 row-start-1">
                        An Feuerwehrveranstaltungen und öffentlichen Anlässen tritt der Musikzug Lauenburg-Süd selbstverständlich in Feuerwehruniform auf. Zu vielen anderen Anlässen wird oftmals die Trachtenuniform gewählt. <br />
                        Der Musikzug Lauenburg-Süd pflegt die Marsch- sowie die böhmische Blasmusik. Das Repertoire ist jedoch sehr bunt gemischt und umfasst ebenso Operettenmelodien, Konzertwalzer, moderne Rhythmen und Stimmungsmusik. <br />
                        Wer Interesse hat, mit uns zu musizieren, kann gern einmal vorbeischauen. Neue Mitglieder sind herzlich willkommen.
                    </p>
                </article>
            </main>
        </div>
    )
}

export default Startseite