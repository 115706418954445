function Modal() {
  return (
    <div className='sticky flex justify-evenly bottom-0 sm:bottom-2 sm:mr-2 sm:self-end sm:w-fit backdrop-blur-sm bg-white/80 py-3 sm:px-3 sm:rounded'>
      <a className={(window.location.pathname === "/mitglied") ? "hidden" : ""} href="/mitglied">
        <button className='border-red-800 border bg-white hover:bg-red-800 max-w-xs hover:text-white font-bold py-2 sm:px-4 px-2 mr-1 sm:mr-3 rounded items-center'>Mitglied werden</button>
      </a>
      <a className={(window.location.pathname === "/buchen") ? "hidden" : ""} href="/buchen">
        <button className='border-red-800 border bg-white hover:bg-red-800 max-w-xs hover:text-white font-bold py-2 sm:px-4 px-2 rounded items-center'>Musikzug buchen</button>
      </a>
      <a className={(window.location.pathname === "/karten") ? "hidden" : ""} href="/karten">
        <button className='border-red-800 border bg-white hover:bg-red-800 max-w-xs hover:text-white font-bold py-2 sm:px-4 px-2 ml-1 sm:ml-3  rounded items-center'>Karten bestellen</button>
      </a>
    </div>
  )
}

export default Modal