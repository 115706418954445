import React from 'react'

export default function Impressum() {
  return (
    <main className="px-5  sm:px-16 sm:pt-10 pt-5 font-light">
      <h1 className='text-3xl mb-4 font-semibold'>Impressum</h1>
      <p>Angaben gemäß §5 TMG</p>
      <h3 className=' font-semibold mt-2'>Feuerwehrmusikzug Lauenburg-Süd</h3>
      <p>Ulrich Grimm</p>
      <p>Dorfstraße 32</p>
      <p>21483 Lanze</p>
      <p>Tel.: 04153/54619</p>
      <h2 className='text-2xl font-semibold mt-10 mb-2'>Haftungsausschluss</h2>
      <h2 className='text-xl mb-4'>Inhalt des Onlineangebotes </h2>
      <p className="text-justify mb-8">
        Der Feuerwehrmusikzug Lauenburg Süd übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen.
        Haftungsansprüche gegen die Autoren, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw.
        durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden sind grundsätzlich ausgeschlossen,
        sofern seitens des Autors kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.
        Alle Angebote sind freibleibend und unverbindlich. Der Feuerwehrmusikzug Lauenburg Süd behält es sich ausdrücklich vor,
        Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
      </p>
      <h2 className='text-xl mb-4'>Verweise und Links </h2>
      <p className="text-justify mb-8">
        Bei direkten oder indirekten Verweisen auf fremde Internetseiten (“Links”), die außerhalb des Verantwortungsbereiches des Feuerwehrmusikzug Lauenburg Süd liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem der Feuerwehrmusikzug Lauenburg Süd von den Inhalten Kenntnis hat und es ihr technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Der Feuerwehrmusikzug Lauenburg Süd erklärt daher ausdrücklich, dass zum Zeitpunkt der Linksetzung die entsprechenden verlinkten Seiten frei von illegalen Inhalten waren. Der Feuerwehrmusikzug Lauenburg Süd hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der gelinkten/verknüpften Seiten. Deshalb distanziert sie sich hiermit ausdrücklich von allen Inhalten aller gelinkten /verknüpften Seiten, die nach der Linksetzung verändert wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.
      </p>
      <h2 className='text-xl mb-4'>Urheber- und Kennzeichenrecht </h2>
      <p className="text-justify mb-8">
        Der Feuerwehrmusikzug Lauenburg Süd ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihr selbst erstellte Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluß zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind! Das Copyright für veröffentlichte, von der Feuerwehrmusikzug Lauenburg Süd selbst erstellte Objekte bleibt allein bei dem Feuerwehrmusikzug Lauenburg Süd . Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht gestattet.
      </p>
      <h2 className='text-xl mb-4'>Datenschutz </h2>
      <p className="text-justify mb-8">
        Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten (Emailadressen, Namen, Telefonnummer, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme und Bezahlung aller angebotenen Dienste ist - soweit technisch möglich und zumutbar - auch ohne Angabe solcher Daten bzw. unter Angabe anonymisierter Daten oder eines Pseudonyms gestattet.
      </p>
      <h2 className='text-xl mb-4'>Rechtswirksamkeit dieses Haftungsausschlusses </h2>
      <p className="text-justify mb-8">
        Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.
      </p>
    </main>
  )
}
