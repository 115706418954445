import React from 'react';

export default function Footer() {
  return (
    <div className=' border-t-2 border-red-800 bottom-0   py-4 w-full text-center text-black'>
        <a href="/impressum" className='mr-10 hover:text-red-800 hover:underline'>Impressum</a>
        <a href="/dsgvo" className='mr-10 hover:text-red-800 hover:underline'>Datenschutzerklärung</a>
        <a href="/bestellen" className='hover:text-red-800 hover:underline'>CD bestellen</a>
    </div>
  )
}
