import React from 'react'

export default function DSGVO() {
  return (
    <main className="px-5  sm:px-16 sm:pt-10 pt-5 font-light">
      <h1 className='text-3xl mb-4 font-semibold'>Datenschutzerklärung</h1>

      <p className=' text-m my-2'> Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
      <p>Ulrich Grimm</p>
      <p>Dorfstraße 32</p>
      <p>21483 Lanze</p>
      <p>Tel.: 04153/54619</p>

      <h2 className='text-xl font-medium mt-10'>Kontaktformular/ Buchungsanfrage</h2>

      <h4 className='text-xl my-2 mb-4 mt-4'>Art und Zweck der Verarbeitung:</h4>

      Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens, sowie bei der Buchungsanfrage eine Telefonnummer, erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung derselben.

      <h4 className='text-xl my-2 mb-4 mt-8'>Rechtsgrundlage:</h4>

      Die Verarbeitung der in das Kontaktformular/ Buchungsanfrage eingegebenen Daten erfolgt auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).
      Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.
      Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in die Buchungsanfrage eingegebenen Daten zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b DSGVO).

      <h4 className='text-xl my-2 mb-4 mt-8'>Empfänger:</h4>

      <p>Ulrich Grimm</p>
      <p>Dorfstraße 32</p>
      <p>21483 Lanze</p>
      <p>Tel.: 04153/54619</p>

      <h4 className='text-xl my-2 mb-4 mt-8'>Speicherdauer:</h4>

      Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.
      Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach Ablauf dieser Fristen.

      <h4 className='text-xl my-2 mb-4 mt-8'>Bereitstellung vorgeschrieben oder erforderlich:</h4>
      
      Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.

      <h4 className='text-xl my-2 mb-4 mt-8'>SSL-Verschlüsselung</h4>
      
      Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.

      <h2 className='text-xl font-medium mt-10'>CD Bestellung</h2>

      <h4 className='text-xl my-2 mb-4 mt-4'>Art und Zweck der Verarbeitung:</h4>

      <p>
        Zum Verkauf von CDs werden von uns zusätzliche Daten erfragt, wie eine Anschrift, um Ihre Bestellung ausführen zu können.
      </p>

      <h4 className='text-xl my-2 mb-4 mt-8'>Rechtsgrundlage:</h4>


      Die Verarbeitung der Daten, die für den Abschluss des Vertrages erforderlich ist, basiert auf Art. 6 Abs. 1 lit. b DSGVO.

      <h4 className='text-xl my-2 mb-4 mt-8'>Empfänger:</h4>

      <p>Ulrich Grimm</p>
      <p>Dorfstraße 32</p>
      <p>21483 Lanze</p>
      <p>Tel.: 04153/54619</p>

      <h4 className='text-xl my-2 mb-4 mt-8'>Speicherdauer:</h4>

      Wir speichern diese Daten in unseren Systemen bis die gesetzlichen Aufbewahrungsfristen abgelaufen sind. Diese betragen grundsätzlich 6 oder 10 Jahre aus Gründen der ordnungsmäßigen Buchführung und steuerrechtlichen Anforderungen.
      
      <h4 className='text-xl my-2 mb-4 mt-8'>Bereitstellung vorgeschrieben oder erforderlich:</h4>

      Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Ohne die Bereitstellung Ihrer personenbezogenen Daten können wir Ihnen keinen Zugang auf unsere angebotene Leistung gewähren.


      <h2 className='text-xl font-medium mt-10'>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>


      <h4 className='text-xl my-2 mb-4 mt-4'>Einzelfallbezogenes Widerspruchsrecht</h4>

      Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.
      Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.

      <h4 className='text-xl my-2 mb-4 mt-8'>Empfänger eines Widerspruchs</h4>

      <p>Ulrich Grimm</p>
      <p>Dorfstraße 32</p>
      <p>21483 Lanze</p>
      <p>Tel.: 04153/54619</p>
    </main>
  )
}

