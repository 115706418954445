import React from 'react'
import one from '../img/jugendmusikzug-lauenburg-sued.jpg'

function Jugendmusikzug() {
  return (
    <main className="px-5  sm:px-16 sm:pt-10 pt-5 font-light">
      <h1 className='text-2xl font-semibold mb-5 w-auto m-auto text-center md:hidden block'>Jugendmusikzug</h1>
      <div className='float-right'>
        <img className='md:max-w-sm lg:max-w-md lg:ml-16 md:ml-5 md:mb-3 mb-5 object-contain ' src={one} alt="" />
        <p className=''></p>
      </div>
      <h1 className='text-2xl font-semibold mb-5 w-auto m-auto md:block hidden'>Jugendmusikzug</h1>
      <p className='text-justify'>
        Zum 50-jährigen Bestehen des Musikzuges wurde der Jugendmusikzug gegründet. Seither nehmen wir junge "Nachwuchskünstler" im Alter ab ca. 9 Jahren mit und ohne musikalische Vorbildung oder Notenkenntnisse auf. <br />
        Die Jugendlichen kommen aus den Gemeinden des Amtes Lütau, der Stadt Lauenburg/Elbe und den umliegenden Gemeinden. <br />
        Für nur 20,- € monatlich erhalten die Kinder von professionellen Musiklehrern monatlich rund 10 Stunden Unterricht. Meist wird auch kostenlos ein Musikinstrument gestellt. <br />
        Die großartige Entwicklung des Jugendmusikzugs lässt sich anhand vieler gelungener Auftritte belegen. Seit 2009 tritt der Jugendfeuerwehrmusikzug neben den Erwachsenen bei den Neujahrskonzerten sowie bei vielen anderen Gelegenheiten auf.
      </p>
    </main>
  )
}

export default Jugendmusikzug