import React, { Component } from "react";
import axios from 'axios';

export default class Buchen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                name: "",
                email: "",
                subject: "Buchung Musikzug",
                message: "",
                sent: false,
                error: null
            },
            showAlert: false,
            alertMessage: "",
            tel: "",
            art: "",
            beschreibung: "",
            zeitOrt: ""
        };
    }

    RenderMessage(event) {
        event.preventDefault();
        const tmp = "<html> <body> <h4>Buchungsanfrage Musikzug </h4> <br> Name: " + this.state.data.name
            + "<br> <br>Tel. Nr.: " + this.state.tel + "<br> <br>Email: " + this.state.data.email
            + "<br> <br> Veranstaltungsart: " + this.state.art + "<br> <br>Ort und Zeit: " + this.state.zeitOrt
            + " <br> <br> Beschreibung:<br> " + this.state.beschreibung + "</body> </html>";
        this.setState({ data: { ...this.state.data, message: tmp } }, () => { this.FormSubmittionHandler(); });
    }

    FormSubmittionHandler() {

        if (/^$/.test(this.state.art) || /(^$)/.test(this.state.zeitOrt) || /(^$)/.test(this.state.beschreibung) || /(^$)/.test(this.state.data.name) || /(^$)/.test(this.state.data.email) || /(^$)/.test(this.state.tel)) {
            this.setState({ alertMessage: "Bitte fülle alle Felder aus!" });
            this.setState({ showAlert: true });
        } else if (!/(.+)[ ](.+)/.test(this.state.data.name)) {
            this.setState({ alertMessage: "Bitte geb den Vor- und Nachnamen an!" });
            this.setState({ showAlert: true });
        } else if (!/(.+)@(.+){2,}\.(.+){2,}/.test(this.state.data.email)) {
            this.setState({ alertMessage: "Bitte geb eine gültige E-Mail Adresse an!" });
            this.setState({ showAlert: true });
        } else if (!/[+]?(([0-9]|[ ]|[-])+){6,30}/.test(this.state.tel)) {
            this.setState({ alertMessage: "Bitte geb eine gültige Telefon Nummer an!" });
            this.setState({ showAlert: true });
        } else if (!document.getElementById('check').checked) {
            this.setState({ alertMessage: "Bitte stimme der Datenschutzerklärung zu!" });
            this.setState({ showAlert: true });
        } else if (document.getElementById('one').value.length !== 0) {
            return;
        } else {
            this.resetForm();
            axios({
                method: 'post',
                url: window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/api/index.php",
                headers: { 'content-type': 'application/json' },
                data: this.state.data
            }).then(result => {
                this.setState({ mailSent: result.data.sent });
                this.setState({ alertMessage: "Nachricht wurde versendet!" });
                this.setState({ showAlert: true });
            }).catch(error => {
                this.setState({ error: error.message });
                this.setState({ alertMessage: "Sendung ist Fehlgeschlagen!" });
                this.setState({ showAlert: true });
            });
        }
    }

    resetForm() {
        document.getElementById('name').value = "";
        document.getElementById('mail').value = "";
        document.getElementById('tel').value = "";
        document.getElementById('art').value = "";
        document.getElementById('text').value = "";
        document.getElementById('zeit').value = "";
        document.getElementById("check").checked = false;
    }

    render() {
        return (
            <main className="px-5  sm:px-16 sm:pt-10 pt-5 font-light">
                <h1 className='text-2xl font-semibold mb-5 w-auto m-auto text-center'>Buchungsanfrage</h1>
                <div className='flex lg:flex-row flex-col'>
                    <div className='lg:w-1/4 text-justify lg:border-b-0 border-zinc-300 border-b-2 pb-4 mb-4'>
                        <p>
                            Sie sind herzlich eingeladen, über das Formular eine Buchungsanfrage an uns zu senden.
                            Wir spielen für Sie auf Geburtstagen, Hochzeiten und jeglicher anderer Form von Veranstaltung, sofern Sie unsere musikalische Begleitung für diese Veranstaltung wünschen!
                        </p>
                        <p>
                            Nachdem Sie die Anfrage gesendet haben, kommen wir schnellstmöglich mit einem Angebot auf Sie zurück!
                        </p>
                    </div>
                    <form className='flex flex-col lg:w-3/4 lg:border-l-2 border-zinc-300 lg:pl-4 lg:ml-4' action='#'>
                        <div className='grid sm:grid-rows-3 grid-rows-5 sm:grid-col-2 grid-col-1 gap-4 '>
                            <input id='name' onChange={(e) => this.setState({ data: { ...this.state.data, name: e.target.value } })} className=' h-10 sm:col-span-2 row-start-1 border-b border-zinc-500 appearance-none bg-transparent py-1 px-2 leading-tight focus:outline-none' type="text" placeholder='Vor- und Nachname' />
                            <input id='mail' onChange={(e) => this.setState({ data: { ...this.state.data, email: e.target.value } })} className='row-start-2 col-span-1 border-b border-zinc-500 appearance-none bg-transparent py-1 px-2 leading-tight focus:outline-none ' type="email" placeholder='E-Mail Adresse' />
                            <input id='tel' onChange={(e) => this.setState({ tel: e.target.value })} className='row-start-3 sm:row-start-2 sm:col-start-2 col-span-1 border-b border-zinc-500border-b border-zinc-500 appearance-none bg-transparent py-1 px-2 leading-tight focus:outline-none ' type="text" placeholder='Tel. Nummer' />
                            <input id='art' onChange={(e) => this.setState({ art: e.target.value })} className='row-start-4 sm:row-start-3 col-span-1 border-b border-zinc-500 appearance-none bg-transparent py-1 px-2 leading-tight focus:outline-none ' type="text" placeholder='Art der Veranstaltung' />
                            <input id='zeit' onChange={(e) => this.setState({ zeitOrt: e.target.value })} className='row-start-5 sm:row-start-3 sm:col-start-2 col-span-1 border-b border-zinc-500 appearance-none bg-transparent py-1 px-2 leading-tight focus:outline-none ' type="text" placeholder='Zeit und Ort' />
                            <input id='one' type="text" className='hidden' />
                        </div>
                        <textarea id='text' onChange={(e) => this.setState({ beschreibung: e.target.value })} rows={4} className='mt-4 mb-2 w-full border-b border-zinc-500 py-1 px-2 focus:outline-none transition-height duration-75 ease-in-out' placeholder='Bitte beschreiben Sie ihre Veranstaltungen und Ihre Wünsche!'></textarea>
                        <div className=" flex flex-col sm:flex-row sm:justify-between sm:items-center mt-4">
                            <div className="mr-2 sm:mb-0 mb-4">
                                <input className=" appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-800 checked:border-red-800 hover:border-red-800 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" id="check" />
                                <label>Ich stimme der <a href="/dsgvo"> <b> <i  className='hover:underline text-red-800'>Datenschutzerklärung</i></b></a> zu</label>
                            </div>
                            <input type="submit" value="Anfrage senden" onClick={e => this.RenderMessage(e)} className='bg-red-800  self-end hover:bg-red-900 max-w-xs text-white font-bold py-2 px-4 rounded items-center'></input>
                        </div>
                        {this.state.showAlert &&
                            <div className='border-red-800 border-l-2 bg-zinc-100 flex items-center justify-center mt-2 px-2 py-2 w-full'>
                                <p> {this.state.alertMessage} </p>
                            </div>
                        }
                    </form>
                </div>
            </main>
        );
    }
}

// eslint-disable-next-line
{/*<div className='flex flex-row justify-between'>
       <p className=' '>Wen möchten sie buchen:</p>
       <div className='flex flex-row'>
        <div className="flex items-center">
        <input className=" appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-800 checked:border-red-800 hover:border-red-800 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mx-2 cursor-pointer" type="checkbox" id="jugendmusikzug" />
        <label>Jugendmusikzug</label>
        </div>
        <div className=" flex items-center">
        <input className=" appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-800 checked:border-red-800 hover:border-red-800 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mx-2 cursor-pointer" type="checkbox" id="musikzug" />
        <label>Musikzug</label>
        </div>
    </div>
</div>

else if (!document.getElementById('jugendmusikzug').checked && !document.getElementById('musikzug').checked) {
    setAlert("Bitte wähle aus wen du buchen möchtest!");
    setShowAlert(true);
}

*/}