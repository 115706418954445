import React, { Component } from "react";
import axios from 'axios';
import cover from '../img/coverCD.jpg'

export default class Bestellen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                name: "",
                email: "",
                subject: "CD Bestellung Musikzug",
                message: "",
                sent: false,
                error: null
            },
            showAlert: false,
            alertMessage: "",
            ort: "",
            plz: "",
            stra: "",
            anz: ""
        };
    }

    RenderMessage(event) {
        event.preventDefault();
        const tmp = "<html> <body> <h4>CD Bestellung Musikzug</h4> <br>An: <br>" + this.state.data.name + "<br>" + this.state.stra + "<br>" + this.state.ort + " " + this.state.plz + "<br> <br>Anzahl: " + this.state.anz + "<br> <br>Email: " + this.state.data.email + "</body> </html>";
        this.setState({ data: { ...this.state.data, message: tmp } }, () => { this.FormSubmittionHandler(); });
    }

    FormSubmittionHandler() {
        if (/^$/.test(this.state.data.name) || /^$/.test(this.state.data.email) || /^$/.test(this.state.stra) || /^$/.test(this.state.plz) || /^$/.test(this.state.ort)) {
            this.setState({ alertMessage: "Bitte fülle alle Felder aus!" });
            this.setState({ showAlert: true });
        } else if (!/(.+)[ ](.+)/.test(this.state.data.name)) {
            this.setState({ alertMessage: "Bitte geb den Vor- und Nachnamen an!" });
            this.setState({ showAlert: true });
        } else if (!/(.+)@(.+){2,}\.(.+){2,}/.test(this.state.data.email)) {
            this.setState({ alertMessage: "Bitte geb eine gültige E-Mail Adresse an!" });
            this.setState({ showAlert: true });
        } else if (!/.+\d.*/.test(this.state.stra)) {
            this.setState({ alertMessage: "Bitte geb als Erstes den Straßennamen und dann eine gültige Hausnummer an!" });
            this.setState({ showAlert: true });
        } else if (!/([0-9]+){5}/.test(this.state.plz)) {
            this.setState({ alertMessage: "Bitte geb eine gültige Postleizahl an!" });
            this.setState({ showAlert: true });
        } else if (!/(([^0-9]){2,})/.test(this.state.ort)) {
            this.setState({ alertMessage: "Bitte geb eine Ort an!" });
            this.setState({ showAlert: true });
        } else if (!/([0-9]+){1,2}/.test(this.state.anz)) {
            this.setState({ alertMessage: "Bitte geb an wie viele CDs du bestellen möchtest an!" });
            this.setState({ showAlert: true });
        } else if (!document.getElementById('check').checked) {
            this.setState({ alertMessage: "Bitte stimme der Datenschutzerklärung zu!" });
            this.setState({ showAlert: true });
        } else if (document.getElementById('one').value.length !== 0) {
            return
        } else {
            this.resetForm();
            axios({
                method: 'post',
                url: window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/api/index.php",
                headers: { 'content-type': 'application/json' },
                data: this.state.data
            }).then(result => {
                this.setState({ mailSent: result.data.sent });
                this.setState({ alertMessage: "Nachricht wurde versendet!" });
                this.setState({ showAlert: true });
            }).catch(error => {
                this.setState({ error: error.message });
                this.setState({ alertMessage: "Sendung ist Fehlgeschlagen!" });
                this.setState({ showAlert: true });
            });
        }
    }

    resetForm() {
        document.getElementById('name').value = "";
        document.getElementById('mail').value = "";
        document.getElementById('stra').value = "";
        document.getElementById('plz').value = "";
        document.getElementById('ort').value = "";
        document.getElementById('anz').value = "";
        document.getElementById("check").checked = false;
    }

    render() {
        return (
            <main className="px-5  sm:px-16 sm:pt-10 pt-5 font-light">
                <h1 className='text-2xl font-semibold mb-5 w-auto m-auto text-center'>CD bestellen</h1>
                <div className='flex lg:flex-row  flex-col-reverse '>
                    <div className='lg:w-1/3 lg:border-r-2 lg:border-t-0 border-t-2 border-zinc-300 pt-4 mt-4 lg:pt-0 lg:mt-0 lg:pr-4 lg:mr-4'>
                        <h2 className=' text-lg font-semibold'>Inhalt der CD</h2>
                        <ol type="1" className='list-decimal list-inside'>
                            <li>Fliegermarsch</li>
                            <li>Mondschein an der Eger</li>
                            <li>Posaunen-Express</li>
                            <li>Schneidig vor</li>
                            <li>Frühling und Liebe</li>
                            <li>Amsel-Polka</li>
                            <li>O Du mein Bayernland</li>
                            <li>Trompetisten-Polka</li>
                            <li>Kuschelpolka</li>
                            <li>Gruß an Kiel</li>
                            <li>Rauschende Birken</li>
                            <li>Wien bleibt Wien</li>
                            <li>Holdrioh, liebes Echo (Kleines Echo)</li>
                            <li>O Du mein Österreich</li>
                            <li>Egerlänger Liedermarsch Nr. 1</li>
                            <li>Kärntner Liedermarsch</li>
                            <li>Bis bald auf Wiederseh'n</li>
                        </ol>
                    </div>
                    <div className='flex flex-col lg:w-2/3'>
                        <div className='flex flex-col lg:flex-row justify-between border-b-2 border-zinc-300 pb-4'>
                            <p className=' text-justify lg:w-3/5 lg:mr-4'>
                                Der Musikzug Lauenburg-Süd produzierte zum 50-jährigen Bestehen seine erste eigene CD in den ehemaligen Musikräumen der Heeresmusikkorps III Lüneburg in der Theodor-Körner-Kaserne.
                                Die CD-Aufnahme bleibt allen Teilnehmern in guter Erinnerung und eine unvergessliche Erfahrung.
                                <br />
                                Hier können Sie die CD mit dem folgenden Online Formular bestellen.
                                <br />
                                <br />
                                <b>Preis: 10€</b>
                                <br />
                                <i>zzgl. 2€ Versandkosten</i>
                            </p>
                            <img src={cover} alt="" className=" lg:w-2/5 lg:h-fit border-2 object-contain my-4 lg:my-0 border-zinc-500" />
                        </div>
                        <form action="">
                            <div className='grid grid-rows-4 grid-col-1 gap-4 mt-4 '>
                                <input id='name' onChange={(e) => { this.setState({ data: { ...this.state.data, name: e.target.value } }); }} className='row-start-1 col-span-1 h-10 border-b border-zinc-500 appearance-none bg-transparent py-1 px-2 leading-tight focus:outline-none' type="text" placeholder='Vor- und Nachname' />
                                <input id='mail' onChange={(e) => { this.setState({ data: { ...this.state.data, email: e.target.value } }) }} className='row-start-2 col-span-1 border-b border-zinc-500 appearance-none bg-transparent py-1 px-2 leading-tight focus:outline-none ' type="email" placeholder='E-Mail Adresse' />
                                <input id='stra' onChange={(e) => { this.setState({ stra: e.target.value }) }} className='row-start-3 col-span-1 border-b border-zinc-500 appearance-none bg-transparent py-1 px-2 leading-tight focus:outline-none ' type="text" placeholder='Straße und Hausnummer' />
                                <input id='one' type="text" className='hidden' />
                                <div className='row-start-4 col col-span-1 flex flex-row'>
                                    <input id='plz' onChange={(e) => { this.setState({ plz: e.target.value }) }} className=' w-1/4 mr-4 border-b border-zinc-500 appearance-none bg-transparent py-1 px-2 leading-tight focus:outline-none ' type="text" placeholder='PLZ' />
                                    <input id='ort' onChange={(e) => { this.setState({ ort: e.target.value }) }} className='w-3/4 border-b border-zinc-500 appearance-none bg-transparent py-1 px-2 leading-tight focus:outline-none' type="text" placeholder='Ort' />
                                </div>
                            </div>
                            <div className='flex flex-row justify-between py-4 items-center text-left'>
                                <input id='anz' onChange={(e) => { this.setState({ anz: e.target.value }) }} className=' w-24 border-b  focus:outline-none border-zinc-500 py-1 px-2 leading-tight' type="number" placeholder='Anzahl' min="1"></input>
                                <div className="ml-4">
                                    <input className=" appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-red-800 checked:border-red-800 hover:border-red-800 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" id="check" />
                                    <label>Ich stimme der <a href="/dsgvo"> <b> <i className='hover:underline text-red-800'>Datenschutzerklärung</i></b></a> zu</label>
                                </div>
                            </div>
                            <div className="flex justify-end w-full">

                                <input type="submit" value="Bestellung senden" onClick={e => { this.RenderMessage(e) }} className='bg-red-800  self-end hover:bg-red-900 max-w-xs text-white font-bold py-2 px-4 rounded items-center'></input>
                            </div>
                            {this.state.showAlert &&
                                <div className='border-red-800 border-l-2 px-2 bg-zinc-100 flex items-center justify-center mt-2 py-2 w-full'>
                                    <p> {this.state.alertMessage} </p>
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </main>
        );
    }
}