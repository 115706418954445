import React from 'react'
import one from '../img/chronik/01-Feuerwehrkapelle-Lanze-19530810.jpg'
import two from '../img/chronik/02-Amtswehrfest-19580629-1.jpg'
import three from '../img/chronik/03-Gruppenbild-1962-Dez.jpg'
import four from '../img/chronik/04-Gerhard-Grube.jpg'
import five from '../img/chronik/05-Musikfest-19750831.jpg'
import six from '../img/chronik/06-Gruppenbild-1983.jpg'
import seven from '../img/chronik/07-Musikzug-Lauenburg-Süd-2008.jpg'
import eight from '../img/chronik/08-Gruppenbild-20070923-FF.jpg'
import nine from '../img/chronik/09-Stefan-Ehmer-Musikalischer-Leiter(1).jpg'
import ten from '../img/chronik/10-konzert-2022.jpeg'


function Chronik() {
    return (
        <main className="px-5 grid sm:px-16 sm:pt-0 pt-5 font-light ">
            <div className='xl:flex mt-2 flex-col items-center max-w-7xl  hidden'>
                <div className=' fixed  backdrop-blur-sm w-fit bg-white/80 py-3 sm:px-0 rounded'>
                    <a href="#1949">
                        <button className=' font-bold hover:underline py-2 sm:px-4 px-2 mr-1 sm:mr-3 rounded items-center'>1949</button>
                    </a>
                    <a href="#1957">
                        <button className=' font-bold hover:underline py-2 sm:px-4 px-2 mr-1 sm:mr-3 rounded items-center'>1957</button>
                    </a>
                    <a href="#1958">
                        <button className=' font-bold hover:underline py-2 sm:px-4 px-2 mr-1 sm:mr-3 rounded items-center'>1958</button>
                    </a>
                    <a href="#1960">
                        <button className=' font-bold hover:underline py-2 sm:px-4 px-2 mr-1 sm:mr-3 rounded items-center'>1960</button>
                    </a>
                    <a href="#1986-1995">
                        <button className=' font-bold hover:underline py-2 sm:px-4 px-2 mr-1 sm:mr-3 rounded items-center'>1986-1995</button>
                    </a>
                    <a href="#1998">
                        <button className=' font-bold hover:underline py-2 sm:px-4 px-2 mr-1 sm:mr-3 rounded items-center'>1998</button>
                    </a>
                    <a href="#2007">
                        <button className=' font-bold hover:underline py-2 sm:px-4 px-2 mr-1 sm:mr-3 rounded items-center'>2007</button>
                    </a>
                    <a href="#2008">
                        <button className=' font-bold hover:underline py-2 sm:px-4 px-2 mr-1 sm:mr-3 rounded items-center'>2008</button>
                    </a>
                    <a href="#2014">
                        <button className=' font-bold hover:underline py-2 sm:px-4 px-2 mr-1 sm:mr-3 rounded items-center'>2014</button>
                    </a>
                    <a href="#2016">
                        <button className=' font-bold hover:underline py-2 sm:px-4 px-2 mr-1 sm:mr-3 rounded items-center'>2016</button>
                    </a>
                    <a href="#2017">
                        <button className=' font-bold hover:underline py-2 sm:px-4 px-2 mr-1 sm:mr-3 rounded items-center'>2017</button>
                    </a>
                    <a href="#2019">
                        <button className=' font-bold hover:underline py-2 sm:px-4 px-2 mr-1 sm:mr-3 rounded items-center'>2019</button>
                    </a>
                    <a href="#2022">
                        <button className=' font-bold hover:underline py-2 sm:px-4 px-2 mr-1 sm:mr-3 rounded items-center'>2022</button>
                    </a>
                </div>
            </div>
            <div className='sm:px-6 px-3 pt-5 xl:pt-20'>
                <div id='1949' className='relative bottom-56 '></div>
                <h1 className='sm:text-xl font-semibold'>Im Sommer 1949 …</h1>
                <p className='my-3 sm:my-6 text-justify'>… beschlossen einige junge Männer aus Lanze, eine Feuerwehrkapelle zu gründen. Dieses war
                    allerdings leichter gesagt als getan. Instrumente waren schwer zu bekommen und wurden schließlich über die damalige Staatsgrenze zur
                    DDR nach Lanze gebracht. Eine Trommel und das entsprechende Notenmaterial stellte der damalige Kapellmeister Kurt Lewald zur Verfügung.</p>
            </div>
            <hr className=' my-6 border-t-2 border-zinc-300' />
            <div className='sm:px-6 px-3'>
                <h1 className='sm:text-xl  font-semibold'>Im August 1949</h1>
                <div className='flex md:inline flex-col-reverse'>
                    <img className=' md:ml-7  md:max-w-sm object-contain md:float-right float-none' src={one} alt="" />

                    <p className='my-3 sm:my-6 lg:max-w-2xl text-justify'>fand die erste Probe auf einem Kornboden statt. Von zehn Anfängern blieben Willi Stehr und Paul
                        Grimm nach. Dazu kam dann Günter Grimm, mit dem man zum Weihnachtsfest immerhin schon einen Walzer und drei Weihnachtslieder
                        intonieren konnte. Das erste Foto wurde von der <b> Feuerwehrkapelle Lanze </b>am 10. August 1953 erstellt. Die Besetzung bestand zu der Zeit aus 5 Musikern.
                        Nach einigen Jahren mussten die Musiker eine Enttäuschung hinnehmen. Der Lehrmeister zog nach Westfalen und sie standen wieder
                        allein auf weiter Flur. Es wurde, so gut es ging, weiter musiziert.
                    </p>
                </div>
            </div>
            <hr className=' my-6 border-t-2 border-zinc-500' />
            <div className='sm:px-6 px-3'>
                <div id='1957' className='relative bottom-56 '></div>
                <h1 className=' sm:text-xl  font-semibold'>Am 19.05.1957</h1>
                <p className='my-3 sm:my-6 text-justify'>
                    wurde im Saal der Gaststätte Eckhoff in Lanze eine Versammlung der Vorstände der einzelnen Wehren aus dem Amtsbezirk Lütau
                    einberufen. Die einzelnen Abgeordneten wurden von unserer Feuerwehrkapelle mit Musik empfangen. Kamerad Fiete Dittmer aus Lütau
                    ging zu den Mitwirkenden auf die Bühne und machte den Vorschlag, gemeinsam zu musizieren, einen neuen Lehrmeister zu suchen und
                    somit eine „Amtswehrkapelle Lütau“ zu gründen.
                    Kamerad Bliss aus Wangelau wurde zum Musikobmann gewählt und beauftragt, sich mit dem Musiker Gerhard Grube in Verbindung zu setzen.
                    Nach diversen Gesprächen wurde dann die <b> Amtswehrkapelle Lütau </b> ins Leben gerufen.
                    Da Gerhard Grube zu dieser Zeit aber noch die Orchester aus Kollow und Witzeeze betreute, lag es nahe, diese Musiker mit der Amtswehrkapelle Lütau zusammenzuführen.</p>
            </div>
            <hr className=' my-6 border-t-2 border-zinc-500' />
            <div className='sm:px-6 px-3'>
                <div id='1958' className='relative bottom-56 '></div>
                <h1 className='sm:text-xl font-semibold'>Am 29.06.1958</h1>
                <div className='flex md:inline flex-col-reverse'>
                    <div className='md:ml-7 md:float-right float-none'>
                        <img className='   md:max-w-sm object-contain ' src={two} alt="" />
                        <p></p>
                    </div>
                    <p className='my-3 sm:my-6 lg:max-w-2xl text-justify'>wurde auf einem Feuerwehrjubiläum in der Gemeinde Basedow erstmalig zusammen musiziert. Erst später
                        stellte sich heraus, dass dies die Gründung des Feuerwehrmusikzuges Lauenburg-Süd war. Probenarbeiten fanden nunmehr gemeinsam
                        einmal wöchentlich und im örtlichem Wechsel in den Orten Kollow, Lanze, Lütau und Witzeeze statt.
                        Bereits am 2.Weihnachtstag 1959 konnte in Witzeeze das 1.Weihnachtskonzert durchgeführt werden. In Lanze folgte dann ein Neujahrskonzert.
                    </p>
                </div>
            </div>
            <hr className=' my-6 border-t-2 border-zinc-500' />
            <div className='sm:px-6 px-3'>
                <div id='1960' className='relative bottom-56 '></div>
                <h1 className='sm:text-xl font-semibold'>Am 15. Mai 1960</h1>
                <div className='flex md:inline flex-col-reverse'>
                    <div className='md:ml-7 md:float-right float-none'>
                        <img className='md:max-h-64 object-contain ' src={three} alt="" />
                        <p className='my-2'>Gruppenfoto 1962</p>
                    </div>

                    <p className='my-3 sm:my-6 lg:max-w-2xl text-justify'>
                        erhielt der <b> "Feuerwehrmusikzug Lauenburg-Süd"</b> auf dem Kreisfeuerwehrverbandstag in Gülzow seinen
                        noch heute gültigen Namen durch den damaligen Landrat Wandschneider und den Kreisbrandmeister Steinbock, die mit einer Kiste Bier
                        und einer Flasche Korn auf die Bühne traten und das Orchester "tauften".
                        Durch unseren Kapellmeister Gerhard Grube wurde in dieser Zeit das Orchester zu einem Mittelpunkt kulturellen Lebens im Kreis Herzogtum Lauenburg.
                    </p>
                </div>
                <hr className=' my-6 border-t-2 border-zinc-300 clear-both' />
                <div className='flex md:inline flex-col-reverse'>
                    <div className='md:ml-7 md:float-right float-none'>
                        <img className='md:h-80 object-contain ' src={four} alt="" />
                        <p className='my-2'>Gerhard Grube</p>
                    </div>

                    <p className='my-3 sm:my-6 md:max-w-2xl text-justify'>

                        Seine unermüdliche Arbeit mit seinen Musikern trägt noch bis heute seine „Handschrift“.
                        Dieses kommt immer wieder in dem guten Klangkörper zum Tragen, den unsere Mitglieder nacheifern. Ein „Markenzeichen“ setzt unsere
                        Marschmusik. Zahlreiche Veranstaltungen geben immer wieder Anlass, die Höchstleistungen auf musikalischem Gebiet zu erreichen.
                    </p>
                    <p className='text-justify md:max-w-2xl'>
                        Nach dem schmerzlichen Verlust unseres Kapellmeisters Gerhard Grube am 02.01.1987 wurde die musikalische Leitung von Jürgen Böttcher
                        übernommen. Diese Aufgabe erfolgreich auszuführen war nicht einfach, zumal sich der „Maßstab“ immer wieder an Gerhard Grube
                        orientiert hat. Jürgen Böttcher konnte seine Musiker überzeugen, dass auch er den Feuerwehrmusikzug Lauenburg- Süd hervorragend
                        leiten kann.
                    </p>
                </div>
                <hr className=' my-6 border-t-2 border-white clear-both md:block hidden' />
                <div className='md:flex md:flex-row md:justify-between jus'>
                    <div className=''>
                        <img className='md:max-h-96  object-contain ' src={five} alt="" />
                        <p className='my-2'>Internationaler Musikwettstreit Lauenburg, 31.08.1975</p>
                    </div>
                    <div className='md:ml-7'>
                        <img className=' md:max-h-96 object-contain ' src={six} alt="" />
                        <p className='my-2'>Gruppenfoto 1983</p>
                    </div>
                </div>
            </div>
            <hr className=' my-6 border-t-2 border-zinc-500' />
            <div className='sm:px-6 px-3 sm:grid sm:grid-cols-2 '>
                <div id='1986-1995' className='relative bottom-56 '></div>
                <h1 className='sm:text-xl sm:col-start-1 font-semibold'>28.09.1986</h1>
                <p className='sm:mt-0 mt-1 text-justify sm:col-start-2 sm:justify-self-end'>Gastkonzert des Musikzuges in Wildemann/Harz</p>
            </div>
            <hr className='sm:mx-0 mx-3 my-3 border-t-2 border-zinc-500 sm:justify-self-end sm:w-3/4 ' />
            <div className='sm:px-6 px-3 sm:grid sm:grid-cols-2'>
                <h1 className='sm:text-xl sm:col-start-1 font-semibold'>04.12.1988</h1>
                <p className=' sm:mt-0 mt-1 text-justify sm:col-start-2 sm:justify-self-end'>Gastkonzert in Sonderburg/Dänemark</p>
            </div>
            <hr className='sm:mx-0 mx-3  my-3 border-t-2 border-zinc-500 sm:justify-self-end sm:w-3/4 ' />
            <div className='sm:px-6 px-3 sm:grid sm:grid-cols-2'>
                <h1 className='sm:text-xl sm:col-start-1 font-semibold'>04.07.1990</h1>
                <p className='sm:mt-0 mt-1 text-justify sm:col-start-2 sm:justify-self-end'>Fahrt mit dem Patenschiff der Stadt Lauenburg/Elbe, dem "Tender Elbe"</p>
            </div>
            <hr className='sm:mx-0 mx-3  my-3 border-t-2 border-zinc-300 sm:justify-self-end sm:w-3/4 ' />
            <div className='sm:px-6 px-3 sm:grid sm:grid-cols-2'>
                <h1 className='sm:text-xl sm:col-start-1 font-semibold'>25.02.1990</h1>
                <p className=' sm:mt-0 mt-1 text-justify sm:col-start-2 sm:justify-self-end'>Wiedervereinigungsfrühshoppen im Elbeclub in Boizenburg</p>
            </div>
            <hr className='sm:mx-0 mx-3  my-3 border-t-2 border-zinc-500 sm:justify-self-end sm:w-3/4 ' />
            <div className='sm:px-6 px-3 sm:grid sm:grid-cols-2'>
                <h1 className='sm:text-xl font-semibold col-start-1'>21.08.1994</h1>
                <p className=' sm:mt-0 mt-1 text-justify sm:col-start-2 sm:justify-self-end'>Musiktreffen in Wyk auf Föhr</p>
            </div>
            <hr className='sm:mx-0 mx-3  my-3 border-t-2 border-zinc-500 sm:justify-self-end sm:w-3/4 ' />
            <div className='sm:px-6 px-3 sm:grid sm:grid-cols-2'>
                <h1 className='sm:text-xl font-semibold col-start-1'>1995</h1>
                <p className=' sm:mt-0 mt-1 text-justify sm:col-start-2 sm:justify-self-end'>Mitwirkung bei der CD-Produktion "Musik aus dem Herzogtum Lauenburg"</p>
            </div>
            <hr className='sm:mx-0 mx-3  my-3 border-t-2 border-zinc-500 sm:justify-self-end sm:w-3/4 ' />
            <div className='sm:px-6 px-3'>
                <p className='my-3 text-justify'>
                    Der Feuerwehrmusikzug Lauenburg-Süd hat in seiner langen Zeit des Bestehens viele großartige Auftritte erlebt, die hier nicht alle
                    dokumentiert werden können. Herausragend sind zahlreiche Vereidigungen der Bundeswehr mit „großem Zapfenstreich“, aber auch viele
                    schöne Dorf- und Feuerwehrfeste sowie die gemeinsamen Konzerte mit anderen Musikorchestern.
                </p>
            </div>
            <hr className=' my-6 border-t-2 border-zinc-500' />
            <div className='sm:px-6 px-3'>
                <div id='1998' className='relative bottom-56 '></div>
                <h1 className='sm:text-xl font-semibold'>1998</h1>
                <p className='my-3 sm:my-6 text-justify'>
                    Der Musikzug feiert im Rahmen des Amtsfeuerwehrfestes in Lanze sein 40-jähriges Bestehen.
                    Erstmals wird eine Chronik zusammengetragen und eine Festschrift gedruckt. Zum Jubiläum
                    beschenkt sich der Musikzug selbst mit neuen Trachtenuniformen mit Weste und Schleife.
                    3 Jubiläumskonzerte in Möln, Bleckede und Lauenburg füllen das Jubiläumsjahr aus.
                </p>
            </div>
            <hr className=' my-6 border-t-2 border-zinc-500' />
            <div className='sm:px-6 px-3'>
                <div id='2007' className='relative bottom-56 '></div>
                <h1 className='sm:text-xl font-semibold'>2007</h1>
                <p className='my-3 sm:my-6 text-justify'>
                    Jürgen Böttcher gibt aus beruflichen Gründen die musikalische Gesamtleitung an seinen Bruder
                    Klaus Böttcher ab, dirigiert aber weiterhin in bewährter Form bei den Auftritten und
                    Konzerten. Klaus Böttcher bringt aus seiner beruflichen Tätigkeit als Musiker im
                    Polizeiorchester Hamburg eine große Erfahrung mit und kann somit neue musikalische Impulse
                    setzen.
                </p>
            </div>
            <hr className=' my-6 border-t-2 border-zinc-300' />
            <div className='sm:px-6 px-3'>
                <h1 className='sm:text-xl font-semibold'>September 2007</h1>
                <p className='my-3 sm:my-6 text-justify'>
                    Der Musikzug Lauenburg-Süd produziert zum 50-jährigen Bestehen seine erste eigene CD in den ehemaligen
                    Musikräumen der Heeresmusikkorps III Lüneburg in der Theodor-Körner-Kaserne.
                    Die CD-Aufnahme bleibt allen Teilnehmern in guter Erinnerung und eine unvergessliche Erfahrung.
                </p>
            </div>
            <hr className=' my-6 border-t-2 border-zinc-500' />
            <div className='md:flex md:flex-row md:justify-evenly jus'>
                <div className=''>
                    <img className='md:max-h-96  object-contain ' src={seven} alt="" />
                    <p className='my-2'>Feuerwehrmusikzug Lauenburg-Süd in Trachtenuniform...</p>
                </div>
                <div className='md:ml-7'>
                    <img className=' md:max-h-96 object-contain ' src={eight} alt="" />
                    <p className='my-2'>...und in Feuerwehruniform - 2008</p>
                </div>
            </div>
            <hr className=' my-6 border-t-2 border-zinc-300' />
            <div className='sm:px-6 px-3 sm:grid sm:grid-cols-2 '>
                <div id='2008' className='relative bottom-56 '></div>
                <h1 className='sm:text-xl sm:col-start-1 font-semibold'>19.01.2008</h1>
                <p className='sm:mt-0 mt-1 text-justify sm:col-start-2 sm:justify-self-end'>Jubiläumskonzert zum 50. in Lauenburg/Elbe</p>
            </div>
            <hr className='sm:mx-0 mx-3 my-3 border-t-2 border-zinc-300 sm:justify-self-end sm:w-3/4 ' />
            <div className='sm:px-6 px-3 sm:grid sm:grid-cols-2'>
                <h1 className='sm:text-xl sm:col-start-1 font-semibold'>02.03.2008</h1>
                <p className=' sm:mt-0 mt-1 text-justify sm:col-start-2 sm:justify-self-end'>Jubiläumskonzert zum 50. in Bleckede</p>
            </div>
            <hr className='sm:mx-0 mx-3 my-3 border-t-2 border-zinc-300 sm:justify-self-end sm:w-3/4 ' />
            <div className='sm:px-6 px-3 sm:grid sm:grid-cols-2'>
                <h1 className='sm:text-xl sm:col-start-1 font-semibold'>27.04 2008</h1>
                <p className=' sm:mt-0 mt-1 text-justify sm:col-start-2 sm:justify-self-end'>Sendung zum Musikzug in Radio "Zusa" in Uelzen inkl. Aufzeichung vom NDR</p>
            </div>
            <hr className='sm:mx-0 mx-3 my-3 border-t-2 border-zinc-300 sm:justify-self-end sm:w-3/4 ' />
            <div className='sm:px-6 px-3 sm:grid sm:grid-cols-2'>
                <h1 className='sm:text-xl sm:col-start-1 font-semibold'>18.05.2008</h1>
                <p className=' sm:mt-0 mt-1 text-justify sm:col-start-2 sm:justify-self-end'>Große Jubiläumsfeier im Rahmen des Amtsfeuerwehrfestes in Lanze</p>
            </div>
            <hr className=' my-6 border-t-2 border-zinc-300' />
            <div className='sm:px-6 px-3'>
                <h1 className='sm:text-xl font-semibold'>18.05.2008</h1>
                <p className='my-3 sm:my-6 text-justify'>
                    Gründung des Jugendfeuerwehrmusikzuges Lauenburg-Süd in Lanze
                    Seither werden von anfänglich beinahe 30 Jugendlichen dauerhaft durch Unterstützung des
                    Fördervereins "FÖVI e.V." zwischen 15 und zwanzig Jugendlichen musikalisch ausgebildet.
                    7 Ausbilder unterrichten montags und donnerstags.
                    Ein Auftritt des Jugendmusikzuges auf dem jährlichen Neujahrskonzert im "Mosaik" in
                    Lauenburg ist seit 2009 ein fester Bestandteil.
                </p>
            </div>
            <hr className=' my-6 border-t-2 border-zinc-500' />
            <div className='sm:px-6 px-3'>
                <div id='2014' className='relative bottom-56 '></div>
                <h1 className='sm:text-xl font-semibold'>21.11. – 23.11.2014</h1>
                <p className='my-3 sm:my-6 text-justify'>
                    Erster auswärtiger Musik-Workshop des Musikzuges und Jugendmusikzuges in Dannenberg als
                    Vorbereitung für das jährliche Neujahrskonzert.
                </p>
            </div>
            <hr className=' my-6 border-t-2 border-zinc-500' />
            <div className='sm:px-6 px-3'>
                <div id='2016' className='relative bottom-56 '></div>
                <h1 className='sm:text-xl font-semibold'>Oktober 2016</h1>
                <div className='flex md:inline flex-col-reverse'>
                    <img className=' md:ml-7 md:max-h-48 object-contain md:float-right float-none' src={nine} alt="" />

                    <p className='my-3 sm:my-6 text-justify'>
                        Stefan Ehmer übernimmt die musikalische Gesamtleitung des Musikzugs.
                    </p>
                </div>
            </div>
            <hr className=' my-6 border-t-2 border-zinc-500' />
            <div className='sm:px-6 px-3'>
                <div id='2017' className='relative bottom-56 '></div>
                <h1 className='sm:text-xl font-semibold'>Januar 2017</h1>
                <p className='my-3 sm:my-6 text-justify'>
                    Erstmalig gibt es nach dem Neujahrs-(Abend-)Konzert am Folgetag ein Konzert mit
                    Frühschoppen, ein voller Erfolg!
                </p>
            </div>
            <hr className=' my-6 border-t-2 border-zinc-500' />
            <div className='sm:px-6 px-3'>
                <div id='2019' className='relative bottom-56 '></div>
                <h1 className='sm:text-xl font-semibold'>Juni 2019</h1>
                <p className='my-3 sm:my-6 text-justify'>
                    Als neuer musikalischer Leiter fungiert Michael Girnt. Beruflich ist er beim
                    Polizeiorchester in Hamburg als Posaunist tätig.
                </p>
            </div>
            <hr className=' my-6 border-t-2 border-zinc-500' />
            <div className='sm:px-6 px-3'>
                <div id='2022' className='relative bottom-56 '></div>
                <h1 className='sm:text-xl font-semibold'>Juni 2022</h1>
                <div className='flex md:inline flex-col-reverse'>
                    <img className=' md:ml-7  md:max-w-xs object-contain md:float-right float-none' src={ten} alt="" />

                    <p className='my-3 sm:my-6 text-justify'>
                        Konzert "Elbklänge" in der einzigartigen Kulisse der "Hitzler Werft" in Lauenburg.
                        <br />
                        (1. Konzert nach über 2 Jahren Corona-Pause vor über 300 Zuhörern)
                    </p>
                </div>
            </div>
            <hr className=' my-6 border-t-2 border-zinc-500' />
            <div className='sm:px-6 px-3'>
                <div id='2019' className='relative bottom-56 '></div>
                <h1 className='sm:text-xl font-semibold'>März 2023</h1>
                <p className='my-3 sm:my-6 text-justify'>
                    Als neuer musikalischer Leiter fungiert Markus Jarms, der sich auch in der Jugendausbildung sehr stark einbringt. 
                </p>
            </div>

            {/*<hr className=' my-6 border-t-2 border-zinc-300' />
            <hr className=' my-6 border-t-2 border-zinc-500' />
            <hr className=' my-6 border-t-2 border-zinc-300 justify-self-end w-3/4 ' />
            <div className='sm:px-6 px-3'>
                <h1 className='sm:text-xl font-semibold'></h1>
                <p className='my-3 sm:my-6 text-justify'></p>
            </div>*/}
        </main>
    )
}

export default Chronik